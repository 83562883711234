<template>
  <el-card class="box-card">
    <div slot="header">
      <span>{{ $tc('addOn', 2) }}</span>
      <router-link
        tag="button"
        class="card-header-button el-button el-button--primary el-button--mini"
        to="/add-ons/create"
      >
        <i class="fad fa-plus"></i>&nbsp;{{ $t('create') }}
      </router-link>
    </div>
    <el-form :inline="true">
      <el-form-item :label="$tc('service')">
        <select-service v-model="form.model.serviceId"></select-service>
      </el-form-item>
      <el-form-item :label="$tc('plan')">
        <select-searchable-service-plan-id v-model="form.model.planId" :service-id="form.model.serviceId"></select-searchable-service-plan-id>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="get">
          {{ $tc('search') }}
        </el-button>
      </el-form-item>
    </el-form>
    <span>
      Registros: {{ table.data.length }}
    </span>
    <el-table v-loading="loading.main" style="width: 100%" :data="table.data" stripe border>
      <el-table-column prop="id" label="ID" width="45"></el-table-column>
      <el-table-column label="" align="center" width="40">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.addOnEnabled" :content="$t('enabled')" placement="right">
            <span>
              <i class="fas fa-circle text-success"></i>
            </span>
          </el-tooltip>
          <el-tooltip v-else :content="$t('disabled')" placement="right">
            <span>
              <i class="fas fa-circle text-danger"></i>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="addOnIcon" label="" width="120">
        <template slot-scope="scope">
          <i v-if="scope.row.addOnIcon" class="fad" :class="scope.row.addOnIcon" size="2x">
          </i>
        </template>
      </el-table-column>
      <el-table-column prop="addOnName" sortable :label="$tc('name', 1)">
        <template slot-scope="scope">
          {{ scope.row.addOnName }}
          <el-tooltip
            v-if="scope.row.addOnDescription"
            :content="scope.row.addOnDescription"
            placement="bottom"
          >
            <span>
              <i class="fad fa-question"></i>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="serviceName" sortable :label="$tc('service', 1)" width="200"></el-table-column>
      <el-table-column prop="addOnBillRecurrence" sortable :label="$t('recurrence')" width="140">
        <template slot-scope="scope">
          {{ $tc($options.filters.toCamelCase(scope.row.addOnBillRecurrence)) }}
        </template>
      </el-table-column>
      <el-table-column prop="addOnPrice" sortable :label="$tc('price', 2)" width="120">
        <template slot-scope="scope">
          {{ scope.row.addOnPrice | formatToCurrencyWithIsoCode }}
        </template>
      </el-table-column>
      <el-table-column :width="110" align="center" :label="$tc('action', 2)" fixed="right">
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click.prevent="onSubmit(scope.row.id)"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click.prevent="onRemove(scope.row.id, scope.$index)"
            ></el-button>
          </el-button-group>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>
<script>
import { i18n } from '../../i18n'

import router from '../../router'

export default {
  data() {
    return {
      form: {
        model: {
          serviceId: '',
          planId: '',
        },
      },
      table: {
        data: [],
      },
      loading: {
        main: false,
      },
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      const _this = this
      _this.loading.main = true
      let url = 'add-ons'

      if (_this.form.model.serviceId && _this.form.model.planId) {
        url += `/${_this.form.model.serviceId}/${_this.form.model.planId}/findByServiceByPlan?onlyEnabled=false`
      } else if (_this.form.model.serviceId) {
        url += `/${_this.form.model.serviceId}/findByService?onlyEnabled=false`
      }

      _this.$http.get(url)
        .then(response => (_this.table.data = response.body))
        .finally(_ => (_this.loading.main = false))
    },
    onSubmit(id) {
      router.push({ name: 'AddOnsUpdate', params: { id } })
    },
    onRemove(id, index) {
      const _this = this
      _this.$confirm(i18n.t('irreversibleProcess'), 'Warning', {
        confirmButtonText: i18n.t('yes'),
        cancelButtonText: i18n.t('no'),
        type: 'warning',
      }).then(() => {
        _this.loading.main = true
        _this.$http.delete(`add-ons/${id}`)
          .then(response => {
            _this.$message({ type: 'success', message: i18n.t('processSuccess') })
            _this.table.data.splice(index, 1)
          })
          .finally(_ => (_this.loading.main = false))
      })
    },
  },
}
</script>
